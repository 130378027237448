import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Loader } from '@/components/ui/loader';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useDeleteDashboard } from '../hooks/use-delete-dashboard';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';

export function DeleteDashboardModal({
  dashboardKey,
  closeFunction,
}: {
  dashboardKey: string;
  closeFunction: VoidFunction;
}) {
  const [randomNumbers, setRandomNumbers] = useState(0);
  const [randomSolve, setRandomSolve] = useState('');
  const queryClient = useQueryClient();
  const { mutateAsync: deleteWorkEngagementDashboard, isPending: isDeleting } =
    useDeleteDashboard();
  const clearAndClose = () => {
    setRandomNumbers(0);
    setRandomSolve('');
    closeFunction();
  };
  const deleteWorkEngagement = () => {
    toast.promise(deleteWorkEngagementDashboard({ key: dashboardKey }), {
      loading: 'Raderar dashboard...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        clearAndClose();
        return 'Dashboard raderades';
      },
      error: () => {
        return 'Misslyckades med att radera dashboard';
      },
    });
  };
  useEffect(() => {
    const random = Math.floor(1000 + Math.random() * 9000);
    setRandomNumbers(random);
  }, []);

  return (
    <AlertDialog open onOpenChange={closeFunction}>
      <AlertDialogContent className='gap-3'>
        <AlertDialogHeader>
          <AlertDialogTitle>Bekräfta radering</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription>
          Bekräfta att du vill radera denna dashboard.
        </AlertDialogDescription>
        {isDeleting && <Loader className='my-7' message='Raderar...' />}
        <form onSubmit={(e) => e.preventDefault()}>
          {!isDeleting && (
            <div className='mb-4 rounded border bg-muted p-4'>
              <div className='mb-4 flex items-center'>
                <span className='p text-muted-foreground'>
                  Fyll i dessa siffror för att bekräfta raderingen:{' '}
                </span>

                <div className='ml-2 rounded-sm bg-foreground p-1 text-background'>
                  <code>{randomNumbers}</code>
                </div>
              </div>

              <Input
                className='bg-background'
                placeholder='Fyll i siffrorna för att bekräfta'
                value={randomSolve}
                onChange={(e) => setRandomSolve(e.target.value)}
              />
            </div>
          )}
          <AlertDialogFooter>
            <Button
              size='sm'
              type='submit'
              variant='destructive'
              onClick={deleteWorkEngagement}
              disabled={randomSolve !== randomNumbers.toString() || isDeleting}
            >
              Bekräfta
            </Button>
            <Button
              size='sm'
              type='button'
              variant='outline'
              onClick={clearAndClose}
            >
              Avbryt
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
